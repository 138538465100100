.comment {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #fefffdba;
}

.comment-header {
  display: flex;
  align-items: center;
}

.profile-pic {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
}

.comment-author {
  margin: 0;
  font-size: 1.2em;
  color: #5c6649;
  ;
}

.comment-text {
  margin: 5px 0;
}

.comment-timestamp {
  margin: 0;
  font-size: 0.8em;
  color: #777;
}