.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
}

.post-container {
  padding: 10px;
}


.more-btn {
  margin-top: auto;

  justify-content: center;
  align-items: center;
  margin: 1rem;
  border: none;
  border-radius: 4px;
  font-weight: bold;
  font-size: .8em;
  text-transform: uppercase;
  padding: 0.6em 1.2em;
  background-color: #e4ebd5;
  color: #97b175;
  box-shadow: 0 8px 24px 0 rgb(255 235 167 / 20%);
  transition: all .3s ease-in-out;
  margin-left: 20%;
}

.more-btn:hover {
  background-color: #d5dfc0;
  color: rgb(227, 236, 211);
}