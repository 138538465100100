.post-container {
    flex-wrap: nowrap;
    display: flex;

    justify-content: space-between;
    gap: 20px;
    /* Adjust this value for the desired space between the post card and the comment form */
}


.comment-form-container {
    flex-basis: 60%;
    /* Adjust this value for the desired width of the comment form */
    min-width: 400px;


    background-color: #c5ddba;
    border-radius: 4px;
    padding: 40px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.243);
    max-height: 650px;
    max-width: 500px;
    margin-right: 5%;
    /* border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px; */
    margin-top: 70px;
    overflow-y: auto
        /* slide in animation */
    ;
}


.postcard-card {

    display: flex;
    flex-direction: column;
    align-items: center;


    flex-basis: 40%;
    min-width: 80vh;
    max-width: 0px;
    width: 450px;

    text-align: center;
    border: 1px solid #ccccccf0;
    padding: 20px;
    background-color: #fff;
    margin-left: 30%;
    max-height: 800px;
    margin-top: 70px;

}

.post-header {
    display: flex;
    align-items: center;
    justify-content: center;
}

.user-name {
    font-weight: 100;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 30px;
}

.user-profile-photo {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
    object-fit: cover;
}

.postcard-image-container {

  width:  70vh; /* Set the desired width */
  height: 70vh; /* Set the desired height */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Hide the overflowing portion of the image */

}

.postcard-image {
    margin-bottom: 10px;
    border-radius: 3px;
    width: auto;
    height: 100%;
    object-fit: cover;
    object-position: center center;
}

.post-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.likes-title {
    font-weight: 100;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    text-align: left;
    margin-bottom: 30px;
}

.date-title {
    font-weight: 100;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 13px;
}

.like-btn,
.comment-btn {
    background-color: transparent;
    border: none;
    margin-right: 10px;
    cursor: pointer;
    flex: 1;
    margin-bottom: 17px;
}

.like-btn i,
.comment-btn i {
    margin-right: 5px;
}

.like-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 10px;
    border: none;
    background-color: transparent;
    position: relative;
}

.like-btn::after {
    width: fit-content;
    height: fit-content;
    position: absolute;
    font-size: 15px;
    color: rgb(80, 80, 80);
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    opacity: 0;
    visibility: hidden;
    transition: .2s linear;
    top: 115%;
}

.icon {
    width: 30px;
    height: 30px;
    transition: .2s linear;
}

.iconn {
    width: 30px;
    height: 30px;
    transition: .2s linear;
}

.icon path {
    fill: rgba(49, 49, 49, 0.687);
    transition: .2s linear;
}

.like-btn:hover>.icon {
    transform: scale(1.2);
}

.like-btn:hover>.icon path {
    fill: rgb(235, 107, 129);
}

.like-btn>.iconn path {
    fill: rgb(235, 107, 129);
    top: 105%;

}


.like-btn:hover::after {
    visibility: visible;
    opacity: 1;
    top: 105%;
}



.buttons-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 10px;
}

.comments-title {
    text-align: left;
    font-weight: 200;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.comments {
    margin-top: 10px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.comment {
    margin-bottom: 10px;
    text-align: left;
}

.comment-author {
    margin-bottom: 5px;
    font-size: smaller;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    text-align: left;
}

.comment-text {
    margin: 0;
}


.delete-btn {
    margin-top: auto;
  
    justify-content: center;
    align-items: center;
    margin: 1rem;
    border: none;
    border-radius: 4px;
    font-weight: bold;
    font-size: .8em;
    text-transform: uppercase;
    padding: 0.6em 1.2em;
    background-color: #e4ebd5;
    color: #97b175;
    box-shadow: 0 8px 24px 0 rgb(255 235 167 / 20%);
    transition: all .3s ease-in-out;

  }

  .delete-btn:hover {
    background-color: #d5dfc0;
    color: rgb(227, 236, 211);
  }