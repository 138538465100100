.image-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    max-width: 800px;
    margin-left: 28%;
    margin-top: 70px;
}

.image-container {
    position: relative;
    width: 250px;
    height: 250px;

}

.image-container img {
    display: block;
    width: 100%;
    height: 250px;
    border-radius: 5px;
}

.image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    border-radius: 5px;
}

.image-container:hover .image-overlay {
    opacity: 1;
}

.image-overlay-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
}

.image-overlay-text span {
    display: block;
    font-size: 16px;
    font-weight: normal;
    margin-top: 10px;
}