.result-card {
    width: 288px;
    height: 400px;
    background: rgb(236, 236, 236);
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    margin-left: 15px;
    margin-top: 5px;
    border-radius: 5px;
}

.voice-chat-card {
    width: 230px;
    height: 60px;
    margin: 20px auto;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 2px 2px 10px #ccc;
    padding: 10px;
    background-color: #e8e8e8;
    transition: ease-in-out;
}

.voice-chat-card:hover {
    background-color: #c8e1b0;
}

.voice-chat-card-header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
    color: black;
    background-color: #333;
}

.username {
    margin: 0;
    font-size: 18px;
    color: black;
}

.status {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: green;
    margin-left: 10px;
}


.status-off {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: rgba(160, 161, 160, 0.192);
    margin-left: 10px;
}