.login {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #ffffff;
    margin-top: 10%;
}

.login .grid {
    width: 100%;
    max-width: 500px;
    /* increase the max-width to adjust the width of the grid */
}

.login form {
    background-color: #c5ddba;
    border-radius: 10px;
    padding: 40px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.243);
    max-width: 600px;
    width: 100%;
}

.login form h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #5c6649;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.login form input {
    margin-bottom: 20px;

}

.login form input:focus {
    background-color: #e5e9dc;

}



.login form .message {
    margin-top: 20px;
    text-align: center;
}

.login form .message a {
    color: #000000;
    font-weight: bold;
    text-decoration: none;
}

.login form .message a:hover {
    text-decoration: underline;
}

.login form .register-button {
    background-color: #ffffff;
    border: 1px solid #000000;
    color: #000000;
    border-radius: 5px;
    padding: 5px 10px;
    font-size: 14px;
    text-decoration: none;
    margin-left: 5px;
}


.login-btn {
    margin: 1rem;
    border: none;
    border-radius: 4px;
    font-weight: bold;
    font-size: .8em;
    text-transform: uppercase;
    padding: 0.6em 1.2em;
    background-color: #e4ebd5;
    color: #97b175;
    box-shadow: 0 8px 24px 0 rgb(255 235 167 / 20%);
    transition: all .3s ease-in-out;
}

.login-btn-link {
    color: #f5f5f5;
    display: block;
    font-size: .75em;
    transition: color .3s ease-out;
}

/*Hover & focus*/
.field input:focus::placeholder {
    opacity: 0;
    transition: opacity .3s;
}

.login-btn:hover {
    background-color: #8dca8a;
    color: #fcf7e6;
    box-shadow: 0 8px 24px 0 rgb(16 39 112 / 20%);
}

.login-btn-link:hover {
    color: #ffeba7;
}



.login-btn1 {
    margin: 1rem;
    border: none;
    border-radius: 4px;
    font-weight: bold;
    font-size: .8em;
    text-transform: uppercase;
    padding: 0.6em 1.2em;
    background-color: #929292ab;
    color: #e4e9de;
    box-shadow: 0 8px 24px 0 rgb(255 235 167 / 20%);
    transition: all .3s ease-in-out;
}

.login-btn1-link {
    color: #f5f5f5;
    display: block;
    font-size: .75em;
    transition: color .3s ease-out;
}

/*Hover & focus*/
.field input:focus::placeholder {
    opacity: 0;
    transition: opacity .3s;
}

.login-btn1:hover {
    background-color: #8dca8a;
    color: #fcf7e6;
    box-shadow: 0 8px 24px 0 rgb(16 39 112 / 20%);
}

.login-btn1-link:hover {
    color: #ffeba7;
}

.message {
    font-size: 15px;
    color: #5c6649;
}