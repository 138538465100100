.sinput-container {
    position: relative;
    display: flex;
    align-items: center;
    margin-left: 15px;
    margin-top: -40px;
    margin-bottom: 15px;
}

.sinput {
    width: 40px;
    height: 40px;
    border-radius: 10px;
    border: none;
    outline: none;
    padding: 18px 16px;
    background-color: transparent;
    cursor: pointer;
    transition: all .5s ease-in-out;
}

.sinput::placeholder {
    color: transparent;
}

.sinput:focus::placeholder {
    color: rgb(131, 128, 128);
}

.sinput:focus,
.sinput:not(:placeholder-shown) {
    background-color: #fff;
    border: 1px solid rgb(196, 196, 196);
    width: 290px;
    padding: 18px 16px 18px 40px;
}

.sicon {
    position: absolute;
    left: 0;
    top: 0;
    height: 40px;
    width: 40px;
    background-color: #fff;
    border-radius: 10px;
    z-index: -1;
    fill: rgb(43, 43, 43);
    border: 1px solid rgb(48, 48, 48);
}

.sinput:hover+.icon {
    transform: rotate(360deg);
    transition: .2s ease-in-out;
}

.sinput:focus+.icon,
.sinput:not(:placeholder-shown)+.icon {
    z-index: 0;
    background-color: transparent;
    border: none;
}