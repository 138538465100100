.more-btnn {
    margin-top: auto;

    justify-content: center;
    align-items: center;
    margin: 1rem;
    border: none;
    border-radius: 4px;
    font-weight: bold;
    font-size: .8em;
    text-transform: uppercase;
    padding: 0.6em 1.2em;
    background-color: #e4ebd5;
    color: #97b175;
    box-shadow: 0 8px 24px 0 rgb(255 235 167 / 20%);
    transition: all .3s ease-in-out;
    margin-left: 53.4%;
}

.more-btnn:hover {
    background-color: #d5dfc0;
    color: rgb(227, 236, 211);
}