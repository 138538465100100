.discussion-container {
    flex-wrap: nowrap;
    display: flex;

    justify-content: space-between;
    gap: 20px;
    /* Adjust this value for the desired space between the post card and the comment form */
}




.discussion-card {

    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    height: 90vh;
    flex-basis: 40%;
    min-width: 40vh;
    max-width: 0px;
    width: 0px;

    text-align: center;
    border: 1px solid #ccccccf0;
    padding: 20px;
    background-color: #fff;
    margin-left: 25%;
    max-height: 800px;
    margin-top: 70px;

}


.message-form-container {
    flex-basis: 60%;
    /* Adjust this value for the desired width of the comment form */
    min-width: 400px;


    background-color: #c5ddba;
    border-radius: 4px;
    padding: 40px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.243);
    max-height: 90vh;
    max-width: 80vh;
    margin-right: 5%;
    /* border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px; */
    margin-top: 70px;
    overflow-y: auto
        /* slide in animation */
    ;
}

.conversation-div {
    margin-top: 10px;
    min-width: 30vh;
    min-height: 8vh;
    display: flex;
    align-items: center;
    text-align: left;
  }
  
  .user-convo-photo {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 15px;
    object-fit: cover;
  }

.conversation-div-unseen {
    margin-top: 10px;
    min-width: 30vh;
    min-height: 8vh;
    border-top-right-radius: 20%;
    border-bottom-right-radius: 20%;
    background-color: #97b175;
    font-weight: 600;
    text-align: left;
    display: flex;
    align-items:center;


    
}