h4 {
    margin-top: 0;
    margin-bottom: 0.5em; /* You can adjust the value as needed */
}


.upload-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 85vh;
    width: 600px;
    background-color: #f5f5f5;
    border-radius: 20px;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3);
    margin: auto;
    margin-top: 5%;
    margin-left: 43%;
}

.preview-image {
    max-height: 20vh;
    width: auto; /* maintain aspect ratio */
  }

.upload-container h1 {
    font-size: 2rem;
    margin: 0 0 20px 0;
    color: #444;
}

.upload-container input[type="file"] {
    margin: 0 0 20px 0;
}

.upload-container button {
    background-color: #afcd7e;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
}

.upload-container button:hover {
    background-color: #a7c741;
}

.upload-container input {
    margin: 0 0 20px 0;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: .8rem;
    width: 80%;
    box-sizing: border-box;
}

.upload-container input[type="text"]:focus {
    outline: none;
    border-color: #d5ffc6;
    box-shadow: 0px 0px 5px 0px rgba(1, 144, 68, 0.197);
}