.com-form {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.com-input {
    height: 100px;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.com-submit-btn {
    align-self: flex-end;
    padding: 5px 10px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.com-submit-btn:hover {
    background-color: #0056b3;
}


.com-btn {
    margin: 1rem;
    border: none;
    border-radius: 4px;
    font-weight: bold;
    font-size: .8em;
    text-transform: uppercase;
    padding: 0.6em 1.2em;
    background-color: #e4ebd5;
    color: #97b175;
    box-shadow: 0 8px 24px 0 rgb(255 235 167 / 20%);
    transition: all .3s ease-in-out;
}

.com-btn-link {
    color: #f5f5f5;
    display: block;
    font-size: .75em;
    transition: color .3s ease-out;
}

/*Hover & focus*/
.field input:focus::placeholder {
    opacity: 0;
    transition: opacity .3s;
}

.com-btn:hover {
    background-color: #8dca8a;
    color: #fcf7e6;
    box-shadow: 0 8px 24px 0 rgb(16 39 112 / 20%);
}

.com-btn-link:hover {
    color: #ffeba7;
}



.com-btn1 {
    margin: 1rem;
    border: none;
    border-radius: 4px;
    font-weight: bold;
    font-size: .8em;
    text-transform: uppercase;
    padding: 0.6em 1.2em;
    background-color: #929292ab;
    color: #e4e9de;
    box-shadow: 0 8px 24px 0 rgb(255 235 167 / 20%);
    transition: all .3s ease-in-out;
}

.com-btn1-link {
    color: #f5f5f5;
    display: block;
    font-size: .75em;
    transition: color .3s ease-out;
}

/*Hover & focus*/
.field input:focus::placeholder {
    opacity: 0;
    transition: opacity .3s;
}

.com-btn1:hover {
    background-color: #8dca8a;
    color: #fcf7e6;
    box-shadow: 0 8px 24px 0 rgb(16 39 112 / 20%);
}

.com-btn1-link:hover {
    color: #ffeba7;
}

.message {
    font-size: 15px;
    color: #5c6649;
}