.settings-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}

.preview-image {
    max-height: 5vh;
    width: auto;
    /* maintain aspect ratio */
}

.upload-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 400px;
}


/* 
form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
} */

label {
    font-size: 1.2rem;
    margin-bottom: 10px;
}


/* input[type="text"],
select {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: none;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    font-size: 1rem;
} */

select {
    margin-bottom: 50px;
}

/* button[type="submit"] {
    background-color: #2ecc71;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 1.2rem;
    cursor: pointer;
} */
/* 
button[type="submit"]:hover {
    background-color: #27ae60;
} */

.delete-btn button {
    margin-top: 20px;
}