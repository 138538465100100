.sidebar {
    position: fixed;
    top: 0;
    left: -350px;
    /* start offscreen */
    bottom: 0;
    width: 350px;
    padding: 20px;
    background-color: #212121;
    animation: slide-in 0.8s forwards;
    overflow-y: auto
        /* slide in animation */
}

/* define slide-in animation */
@keyframes slide-in {
    0% {
        left: -350px;
    }

    /* start offscreen */
    100% {
        left: 0;
    }

    /* end onscreen */
}


.sidebar a {
    display: block;
    padding: 10px;
    color: #686868;
    text-decoration: none;
    font-size: large;
    padding: 20px;
}

.sidebar a.active {
    font-weight: bold;
    color: #ebeee1;
}

.sidebar:hover {
    background-color: #1e1e1e;
}

.logo {
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    color: #91A93C;
    display: inline-block;
    font-size: 30px;
}

.logo-img {
    width: 140px;
    height: 140px;
    display: inline-block;
    margin-left: -30px;
    vertical-align: middle;
    margin-bottom: 0px;
    margin-top: 10px;
    transition: transform 0.2s ease-in-out;
}

.logo-img:hover {
    transform: scale(1.1);
}

.icons {
    height: 25px;
    width: 25px;
    margin-right: 10px;
}


/* .sidebar.sidebar.sidebar {
   position: fixed;
   top: 0;
   left: 0;
   bottom: 0;
   width: 250px;
   padding: 20px;
   background-color: #212121;
} */


.active-notify {

    color: green
}

/* inactive-notify {
    
} */