.postcards-card {

    display: flex;
    flex-direction: column;
    align-items: center;


    flex-basis: 40%;
    min-width: 650px;
    max-width: 0px;
    width: 850px;

    text-align: center;
    border: 1px solid #ccccccf0;
    padding: 20px;
    background-color: #fff;
    margin-left: 30%;
    max-height: 800px;
    margin-top: 70px;

    /* 
    background: linear-gradient(rgba(145, 169, 60, 0.8), );
    border-radius: 10px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1); */
    /* border: 1px solid #ccccccf0; */

    background: radial-gradient(rgba(255, 255, 255, 0.8), rgba(157, 198, 156, 0.26));
    border-radius: 3px;
    box-shadow: 0px 0px 3px rgba(255, 255, 255, 0.8);

}


.postcards-image-container {

    width: 400px;
    /* Set the desired width */
    height: 400px;
    /* Set the desired height */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    /* Hide the overflowing portion of the image */

}

.postcards-image {
    margin-bottom: 10px;
    border-radius: 3px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
}





.post-card {
    width: 450px;
    margin: 0 auto;
    text-align: center;
    border: 1px solid #ccccccf0;
    padding: 20px;
    background-color: #fff;

}

.post-header {
    display: flex;
    align-items: center;
    justify-content: center;
}

.user-name {
    font-weight: 100;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 30px;
}

.user-profile-photo {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
    object-fit: cover;
}

.post-image {
    width: 100%;

    margin-bottom: 10px;
    border-radius: 3px;
}

.post-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.likes-title {
    font-weight: 100;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    text-align: left;
    margin-bottom: 30px;
}

.date-title {
    font-weight: 100;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 13px;
}

.like-btn,
.comment-btn {
    background-color: transparent;
    border: none;
    margin-right: 10px;
    cursor: pointer;
    flex: 1;
    margin-bottom: 17px;
}

.like-btn i,
.comment-btn i {
    margin-right: 5px;
}

.like-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 10px;
    border: none;
    background-color: transparent;
    position: relative;
}

.like-btn::after {
    width: fit-content;
    height: fit-content;
    position: absolute;
    font-size: 15px;
    color: rgb(80, 80, 80);
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    opacity: 0;
    visibility: hidden;
    transition: .2s linear;
    top: 115%;
}

.icon {
    width: 30px;
    height: 30px;
    transition: .2s linear;
}

.iconn {
    width: 30px;
    height: 30px;
    transition: .2s linear;
}

.icon path {
    fill: rgba(49, 49, 49, 0.687);
    transition: .2s linear;
}

.like-btn:hover>.icon {
    transform: scale(1.2);
}

.like-btn:hover>.icon path {
    fill: rgb(235, 107, 129);
}

.like-btn>.iconn path {
    fill: rgb(235, 107, 129);
    top: 105%;

}


.like-btn:hover::after {
    visibility: visible;
    opacity: 1;
    top: 105%;
}



.buttons-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 10px;
}

.comments-title {
    text-align: left;
    font-weight: 100;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.comments {
    margin-top: 10px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.comment {
    margin-bottom: 10px;
    text-align: left;
}

.comment-author {
    margin-bottom: 5px;
    font-size: smaller;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    text-align: left;
}

.comment-text {
    margin: 0;
}